import axios from "axios";

const config = {
    apiUrl: process.env.REACT_APP_API_URL
};

export const CategorygetAll = async (menuId) => {
    return await axios
        .post(`${config.apiUrl}/categories/getAllData`, {
            MenuId: Number(menuId)
        })
        .then((response) => {
            console.log(menuId);
            console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(Promise.reject(error));
            return Promise.reject(error);
        });
};
