import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import c from "./input.module.css";

export default function SearchDropdown({
  id,
  options,
  label,
  value,
  onChange,
  variant,
  disabled,
  warn,
  warning,
  size,
}) {
  return (
    <Autocomplete
      freeSolo
      id={id || "free-solo-search"}
      disableClearable
      disabled={disabled}
      options={options.map((option) => option.key || "")}
      inputValue={value}
      onChange={(e, v) => {
        onChange(v);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={c.input}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          label={label}
          variant={variant}
          disabled={disabled}
          //onKeyPress={(e) => handleKeyPress(e)}
          type={"text"}
          error={warn}
          helperText={warn && warning}
          size={size}
        />
      )}
    />
  );
}
