import { TextField, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";


// import { IconInfoCircle } from "@tabler/icons";

import c from "./input.module.css";
import { useState } from "react";

export default function Input({
  value,
  onChange = () => {},
  variant = "standard",
  type = "text",
  label,
  warn,
  warning,
  disabled,
  size = "normal",
  onEnterPress = () => {},
  multiline,
  customAdormentEnd,
  customAdormentStart,
  info,
  isZoomLevel,
  i18n,
  minNumber,
  maxNumber
}) {
  const [show, setShow] = useState(false);
  const [selectedLang, setSelectedLang] = useState("tr");

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  let inputProps = {};

  if (i18n) {
    inputProps.startAdornment = (
      <InputAdornment position="start">
        <FormControl
          sx={{ m: 1, minWidth: 50, ml: 0 }}
          size="small"
          variant="standard"
        >
          <Select
            labelId="select-lang-label"
            id="select-lang"
            value={selectedLang}
            onChange={(e) => setSelectedLang(e.target.value)}
          >
            <MenuItem value={"tr"}>TR</MenuItem>
            <MenuItem value={"en"}>EN</MenuItem>
          </Select>
        </FormControl>
      </InputAdornment>
    );
  }

  if (type === "phone") {
    inputProps.inputMode = "numeric";
    inputProps.pattern = "[0-9]*";
    inputProps.startAdornment = <InputAdornment position="start">+90</InputAdornment>;
  } else if (type === "password") {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <IconButton
          className={c.eye} //? cant apply module class type
          style={{ width: "2em", marginBottom: "0", marginRight: "1px" }}
          aria-label="toggle password visibility"
          onClick={() => {
            setShow(!show);
          }}
          onMouseDown={() => {}}
          edge="end"
        >
          {show ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );
  } else if (customAdormentEnd) {
    inputProps.endAdornment = <InputAdornment position="end">{customAdormentEnd}</InputAdornment>;
  } else if (customAdormentStart) {
    inputProps.startAdornment = (
      <InputAdornment position="start">{customAdormentStart}</InputAdornment>
    );
  } else if (info) {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <CustomWidthTooltip
          title={info}
          placement="right"
        >
          <IconButton
            className={c.info} //? cant apply module class type
            style={{ width: "2em", marginBottom: "0", marginRight: "1px" }}
            edge="end"
          >
            {/* <IconInfoCircle
              stroke={2}
              color="rgb(94, 53, 177)"
            /> */}
          </IconButton>
        </CustomWidthTooltip>
      </InputAdornment>
    );
  }
  if (isZoomLevel) {
    inputProps.startAdornment = (
      <InputAdornment position="start">
        <Typography
          variant="overline"
          display="block"
          style={{ fontSize: "20px" }}
        >
          %
        </Typography>
      </InputAdornment>
    );
  }

  if (type === 'number') {
    inputProps.inputProps = {
      min: minNumber,
      max: maxNumber
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onEnterPress();
    }
    if (type === "phone") {
      if (value.length < 1 && e.key !== "5") {
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
      if (value.replace(/ /g, "").length === 10) {
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
    }
  };

  return (
    <div className={c.container}>
      <TextField
        className={c.input}
        value={i18n ? value[selectedLang] : value}
        onChange={(e) => (i18n ? onChange(e.target.value, selectedLang) : onChange(e.target.value))}
        label={label}
        variant={variant}
        disabled={disabled}
        onKeyPress={(e) => handleKeyPress(e)}
        type={type === "password" ? (show ? "text" : "password") : type}
        error={warn}
        helperText={warn && warning}
        InputProps={inputProps}
        size={size}
        multiline={multiline}
        rows={multiline ? 4 : 1}
        onKeyDown={(e) => {
          if (
            type === "number" &&
            (e.key === "e" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",")
          )
            e.preventDefault();
        }}
      />
    </div>
  );
}
