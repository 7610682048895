import React, { useEffect } from "react";
import { ProductsActions } from "../Redux/Actions/Products.action";
import { CartActions } from "../Redux/Actions/Cart.action";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { useState } from "react";
import RecommendedSwipper from "./RecommendedSwipper";
import MyNavbar from "./MyNavbar";
import axios from "axios";

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
};

function ProductsDetailsCard() {
  const { id , menuId} = useParams();
  const dispatch = useDispatch();
//   let products = useSelector((state) => state.Products.products);

//   let productDetails = {};

//   products.map((product) => {
//     if (product.Id == id) {
//       productDetails = product;
//     }
//   });

  const [rating, setRating] = useState(0);
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };

  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);
  const [product, setProduct] = useState([])

  const addToCart = (product) => {
    dispatch(CartActions.addToCart(product));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${config.apiUrl}/product/getData`,
          {
            MenuId: Number(menuId),
            Id: Number(id),
          }
        );
        setProduct(response.data.Result);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  console.log(product);
  return (
    <>
      <MyNavbar text={"Siparişler"} navbarType={"orders"} />
      <div className="container Product-Detail-Container">
        <div className="row ">
          <div className="col-md-6">
            <img
              src={product.ProductImageUrl}
              alt={product.ProductName}
              className="img-fluid Product-Detail-img"
            />
          </div>

          <div className="col-md-6">
            <div className="Product-Details-Up">
              <p className="text-uppercase text-black-50 mt-3 mb-1 ">
                {product.CategoryName}
              </p>
              <Rating
                onClick={handleRating}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                onPointerMove={onPointerMove}
                size={25}
              />
            </div>

            <h1 className="text-center mt-2">{product.ProductName}</h1>

            <h1 className=" fw-bold my-2 text-center">
              {product.ProductPrice}&#x20BA;
            </h1>
            <h5 className="mb-1">İçindekiler </h5>
            <p className="lead">{product.ProductDescription}</p>
            <div className="Product-Details-Button">
              <button className="btn btn-outline-primary card-button">
                Garsona Sor
              </button>
              <button
                className="btn btn-outline-primary card-button"
                onClick={() => addToCart(product)}
              >
                Siparişe Ekle
              </button>
            </div>
          </div>
        </div>
        <h1 className="mt-4">Önerilenler</h1>
        <RecommendedSwipper categoryId={product.CategoryId} currentProductId={product.Id}/>
      </div>
    </>
  );
}

export default ProductsDetailsCard;
