import React from "react";
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";

function MyFooter() {
    return (
        <footer className="d-flex flex-wrap justify-content-center align-items-center py-3 my-2 border-top Footer-Container">
            <div className="col-md-4 d-flex align-items-center">
                <a
                    href="/"
                    className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1 "
                >
                    <svg className="bi" width="30" height="24"></svg>
                </a>
                <span className="mb-3 mb-md-0 text-muted d-flex align-items-center">
                    GetMenu &copy; 2022 Company, Inc
                </span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex mx-2 align-items-center">
                <li className="ms-3">
                    <a className="text-muted" href="#">
                        <svg width="24" height="24">
                            <BsFacebook />
                        </svg>
                    </a>
                </li>
                <li className="ms-3">
                    <a className="text-muted" href="#">
                        <svg width="24" height="24">
                            <BsInstagram />
                        </svg>
                    </a>
                </li>
                <li className="ms-3">
                    <a className="text-muted" href="#">
                        <svg width="24" height="24">
                            <BsTwitter />
                        </svg>
                    </a>
                </li>
            </ul>
        </footer>
    );
}

export default MyFooter;
