import { CartConstants } from "../Constants/Cart.constants";

const initialState = {
  cart: [],
  numberCart: 0,
  newProduct: [],
  deneme: [],
};

// export function CartReducer(state = initialState, action) {
//     switch (action.type) {
//         case CartConstants.ADD_TO_CART: {
//             let addedItems = state.cart.find(
//                 (c) => c.product.id === action.payload.product.id
//             );
//             if (addedItems) {
//                 let newState = state.cart.map((cartItem) => {
//                     if (cartItem.id === action.payload.id)
//                         return cartItem.quantity + 1;
//                     return cartItem;
//                 });
//                 return newState;
//             } else {
//                 return [...state, ...action.payload];
//             }
//         }
//         default:
//             return state;
//     }
// }

// export function CartReducer(state = initialState, action) {
//     switch (action.type) {
//         case CartConstants.ADD_TO_CART:
//             return {
//                 ...state,
//                 cart: [
//                     ...state.cart,
//                     state.cart.length > 0
//                         ? state.cart.map((perCart) =>
//                               perCart.id === action.payload.id
//                                   ? {
//                                         ...perCart,
//                                         quantity: perCart.quantity + 1
//                                     }
//                                   : action.payload
//                           )
//                         : action.payload
//                 ]
//             };
//         default:
//             return state;
//     }
// }

export function CartReducer(state = initialState, action) {
  console.log("first", action.type);
  switch (action.type) {
    case CartConstants.ADD_TO_CART: {
      if (state.cart.length === 0) {
        let cart = action.payload;
        cart.quantity = 1;
        state.cart.push(cart);
        console.log("first");
        return {
          ...state,
          numberCart: state.numberCart + 1,
        };
      } else {
        let check = false;
        state.cart.map((item, key) => {
          if (item.Id === action.payload.Id) {
            state.cart[key].quantity++;
            check = true;
            console.log("second");
          }
          // return {
          //     ...state,
          //     numberCart: state.numberCart + 1
          // };
        });
        if (!check) {
          let _cart = action.payload;
          _cart.quantity = 1;
          state.cart.push(_cart);
          console.log("third");
          return {
            ...state,
            numberCart: state.numberCart + 1,
          };
        }
      }

      return {
        ...state,
      };
    }
    case CartConstants.GET_ALL_PRODUCT:
      return {
        ...state,
        newProduct: action.payload,
      };
    case CartConstants.INCREASE_TO_QUANTITY:
      state.cart.map((item, key) => {
        if (item.Id === action.payload.Id) {
          item.quantity++;
        }
        return {
          ...state,
        };
      });

      return {
        ...state,
      };
    case CartConstants.DECREASE_TO_QUANTITY:
      state.cart.map((item, key) => {
        if (item.Id === action.payload.Id && item.quantity > 1) {
          state.cart[key].quantity--;
        }
      });

      return {
        ...state,
      };
    case CartConstants.DELETE_TO_CART:
      return {
        numberCart: state.numberCart - 1,
        cart: state.cart.filter((item) => {
          return item.Id !== action.payload.Id;
        }),
      };
    case CartConstants.RESET_TO_CART:
      console.log("reset reducer");
      return {
        numberCart: 0,
        cart: [],
      };
    default:
      return state;
  }
}
