import React from "react";
import ProductCard from "./ProductCard";
import CategorySwipper from "./CategorySwipper";
import PopoverMenu from "./PopoverMenu";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import MyNavbar from "./MyNavbar";

function HomeGrid() {
    // const [post, setPost] = useState(null);
    // useEffect(() => {
    //     axios.get("/categories/getAllData").then((response) => {
    //         setPost(response.data);
    //     });
    // }, [post]);
    // console.log(post);

    return (
        <div>
            <MyNavbar text = {"Siparişler"} navbarType={"orders"} />
            <div className="container fix-margin">
                <CategorySwipper />
                <ProductCard />
            </div>

            <PopoverMenu />
        </div>
    );
}

export default HomeGrid;
