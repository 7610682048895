import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Card } from "reactstrap";
import { Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { CategoriesActions } from "../Redux/Actions/Categories.action";
import { CategoriesFilterActions } from "../Redux/Actions/CategoriesFilter.action";
import { ProductsActions } from "../Redux/Actions/Products.action";
import { useParams } from "react-router-dom";

function CategorySwipper() {
    const dispatch = useDispatch();
    let categories = useSelector((state) => state.Categories.categories);
    const { menuId } = useParams();
    let deneme = 1;
    const getCategories = (menuId) => {
        dispatch(CategoriesActions.getAll(menuId));
    };
    console.log(menuId);

    const filterCategories = (id) => {
        dispatch(CategoriesFilterActions.setCatFilter(id));
    };
    const getProducts = (menuId) => {
        dispatch(ProductsActions.getAll(menuId));
    };

    useEffect(() => {
        if (menuId) {
            getCategories(menuId);
            getProducts(menuId);
        }
    }, []);

    if (!categories) return null;

    return (
        <Swiper
            pagination={{
                dynamicBullets: true
            }}
            modules={[Pagination]}
            className="mySwiper"
            breakpoints={{
                180: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                320: {
                    slidesPerView: 3.5,
                    spaceBetween: 10
                },

                480: {
                    slidesPerView: 3.5,
                    spaceBetween: 10
                },

                720: {
                    slidesPerView: 5.5,
                    spaceBetween: 10
                },
                1280: {
                    slidesPerView: 8.5,
                    spaceBetween: 10
                },
                1440: {
                    slidesPerView: 10.5,
                    spaceBetween: 10
                }
            }}
        >
            {categories.map((category, i) => {
                return (
                    <SwiperSlide className="rounded-img" key={i}>
                        <Card onClick={() => filterCategories(category.Id)}>
                            <img
                                className="card-img-top img-fluid Category-Swipper-img"
                                alt="Sample"
                                src={category.CategoryImage}
                            />
                            <p className="text-center fw-semibold lh-sm  text-wrap">
                                {category.CategoryName}
                            </p>
                        </Card>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

export default CategorySwipper;
