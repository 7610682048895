import React, { useEffect } from "react";
import { ProductsActions } from "../../Redux/Actions/Products.action";
import { CartActions } from "../../Redux/Actions/Cart.action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { useState } from "react";
import RecommendedSwipper from "../RecommendedSwipper";
import axios from "axios";
import MyNavbar from "../MyNavbar";
import { Button } from "reactstrap";

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
};

function OrderDetail() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  function transformObject(data) {
    const transformedData = [];

    const length = Math.max(
      data.ProductId.length,
      data.ProductName.length,
      data.ProductPrice.length,
      data.ProductDescription.length,
      data.ProductImageUrl.length,
      data.CategoryId.length,
      data.CategoryName.length
    );

    for (let i = 0; i < length; i++) {
      const transformedItem = {
        Id: data.Id,
        RestaurantId: data.RestaurantId,
        RestaurantName: data.RestaurantName,
        OrderId: data.OrderId,
        TableId: data.TableId,
        TableName: data.TableName,
        TableNumber: data.TableNumber,
        OrderNotes: data.OrderNotes,
      };

      transformedItem.ProductId = data.ProductId[i] || null;
      transformedItem.ProductName = data.ProductName[i] || null;
      transformedItem.ProductPrice = data.ProductPrice[i] || null;
      transformedItem.ProductDescription = data.ProductDescription[i] || null;
      transformedItem.ProductImageUrl = data.ProductImageUrl[i] || null;
      transformedItem.CategoryId = data.CategoryId[i] || null;
      transformedItem.CategoryName = data.CategoryName[i] || null;

      transformedData.push(transformedItem);
    }

    return transformedData;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${config.apiUrl}/orderRequest/getData`,
          {
            RestaurantId: 1,
            TableId: location.state.id,
          }
        );
        let newList = transformObject(response.data.Result);
        setProducts(newList);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [location.state.id]);
  console.log(products);
  return (
    <>
      <MyNavbar text={"Bildirimler"} navbarType={"waiters"} />
      <div className="container Product-Detail-Container">
        {products.map((product) => (
          <div className="row mt-5 border-top p-3">
            <div className="col-md-6">
              <img
                src={product.ProductImageUrl}
                alt={product.ProductName}
                className="img-fluid Product-Detail-img"
              />
            </div>

            <div className="col-md-6">
              <div className="Product-Details-Up">
                <p className="text-uppercase text-black-50 mt-3 mb-1 ">
                  {product.CategoryName}
                </p>
              </div>

              <h2 className="text-center mt-2">{product.ProductName}</h2>

              <h3 className=" fw-bold my-2 text-center">
                {product.ProductPrice}&#x20BA;
              </h3>
              <h5 className="mb-1">İçindekiler </h5>
              <p className="lead">{product.ProductDescription}</p>
              <h5 className="mb-1">Sipariş Notu </h5>
              <p className="lead">{product.OrderNotes}</p>
            </div>
          </div>
        ))}
        <div className="waiter-button-container ">
          <Button onClick={() => navigate("/waiters")} size="lg">
          Geri
          </Button>
        </div>
      </div>
    </>
  );
}

export default OrderDetail;
