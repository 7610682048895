import { CategoriesFilterConstants } from "../Constants/CategoriesFilter.constants";

const initialState = {
    filter: "all"
};

export function CategoriesFilter(state = initialState, action) {
    switch (action.type) {
        case CategoriesFilterConstants.GET_CATEGORIES_ID:
            return {
                filter: action.payload
            };
        default:
            return state;
    }
}
