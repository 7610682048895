import { CategoriesConstants } from "../Constants/Categories.constants";

const initialState = { categories: [] };

export function Categories(state = initialState, action) {
    switch (action.type) {
        // case CategoriesConstants.GET_CATEGORIES_REQUEST:
        //     return {
        //         loading: true
        //     };
        case CategoriesConstants.GET_CATEGORIES_SUCCESS:
            return {
                loading: false,
                categories: action.payload.Result
            };

        // case CategoriesConstants.GET_CATEGORIES_FAILURE:
        //     return {
        //         loading: true,
        //         error: action.error.message
        //     };
        default:
            return state;
    }
}
