import { CartConstants } from "../Constants/Cart.constants";

export const CartActions = {
    addToCart,
    deleteToCart,
    IncreaseQuantity,
    DecreaseQuantity,
    GetAllProduct,
    resetToCart
};

function addToCart(cartItem) {
    return (dispatch) => {
        dispatch({
            type: CartConstants.ADD_TO_CART,
            payload: cartItem
        });
    };
}

function deleteToCart(cartItem) {
    return (dispatch) => {
        dispatch({
            type: CartConstants.DELETE_TO_CART,
            payload: cartItem
        });
    };
}

function resetToCart() {
    return (dispatch) => {
        console.log("reset girdi")
        dispatch({
            type: CartConstants.RESET_TO_CART,
        });
    };
}

function IncreaseQuantity(cartId) {
    return (dispatch) => {
        dispatch({
            type: CartConstants.INCREASE_TO_QUANTITY,
            payload: cartId
        });
    };
}
function DecreaseQuantity(cart) {
    return {
        type: CartConstants.DECREASE_TO_QUANTITY,
        payload: cart
    };
}

function GetAllProduct(product) {
    return {
        type: CartConstants.DECREASE_TO_QUANTITY,
        payload: product
    };
}
