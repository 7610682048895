import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { BsWallet2 } from "react-icons/bs/";
import { AiOutlineClose } from "react-icons/ai";
import { Button, ButtonGroup, Label } from "reactstrap";
import { useEffect } from "react";
import { MdNotificationsOff, MdRemoveShoppingCart } from "react-icons/md";

import axios from "axios";
import { useParams } from "react-router-dom";
import { RiDeleteBin5Fill } from "react-icons/ri";

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
};

function WaiterNotificationModal({ modalType, setOpen, open , notificationData}) {
  const onCloseModal = () => setOpen(false);
  const { restId, tableId, menuId } = useParams();
  const [data, setData] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${config.apiUrl}/popOver/getAllData`,
  //         {
  //           RestaurantId: 1,
  //         }
  //       );
  //       setData(response.data.Result);
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  //   const interval = setInterval(fetchData, 2000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);
  const handleDelete = async (item) => {
    const reqBody = {
      Id: item.Id,
      UpdateUserId: 1,
    };
    try {
      await axios.post(`${config.apiUrl}/popOver/deleteData`, reqBody);
      console.log("Başarılı");
      //   window.location.reload();
    } catch (error) {
      console.log("delete table error:", error);
    }
  };

  return (
    <div>
      <div>
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          blockScroll={true}
          animationDuration={0}
          showCloseIcon={false}
        >
          <div className="Modal-Fixed-Nav">
            <div className="Modal-Nav p-3 mb-1 shadow">
              <button
                className="Modal-Close-Button "
                onClick={() => onCloseModal()}
              >
                <AiOutlineClose />
              </button>
            </div>
          </div>
          {notificationData === null || notificationData === undefined ? (
            <div className="No-Cart">
              <h6 className="text-center">Bildirim bulunmamaktadır.</h6>
              <MdNotificationsOff className="No-Cart-Icon" />
            </div>
          ) : (
            notificationData.map((item, i) => (
              <div className="row no-gutters" key={i}>
                <div className="card mb-3">
                  <div className="row">
                    <div className="col-4 d-flex justify-content-center align-items-center p-0">
                      {item.TableName}
                    </div>
                    <div className="col-6 d-flex">
                      {item.PopOverTypeId === 1 && (
                        <div className="card-body d-flex align-items-center">
                          "Garson Çağırıyor!"
                        </div>
                      )}
                      {item.PopOverTypeId === 2 && (
                        <div className="card-body d-flex align-items-center">
                          "Nakit Hesap Ödeyecek!"
                        </div>
                      )}
                      {item.PopOverTypeId === 3 && (
                        <div className="card-body d-flex align-items-center ">
                          "Kart İle Hesap Ödeyecek!"
                        </div>
                      )}
                    </div>
                    <div className="col-2 d-flex justify-content-center align-items-center">
                      <div>
                        <div>
                          <RiDeleteBin5Fill
                            className="Order-Delete-Icon "
                            onClick={() => {
                              handleDelete(item);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </Modal>
      </div>
    </div>
  );
}

export default WaiterNotificationModal;
