export const CategoriesConstants = {
    GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
    GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",

    STORE_OR_UPDATE_CATEGORIES_REQUEST: "STORE_OR_UPDATE_CATEGORIES_REQUEST",
    STORE_OR_UPDATE_CATEGORIES_SUCCESS: "STORE_OR_UPDATE_CATEGORIES_SUCCESS",
    STORE_OR_UPDATE_CATEGORIES_FAILURE: "STORE_OR_UPDATE_CATEGORIES_FAILURE",

    DELETE_CATEGORIES_REQUEST: " DELETE_CATEGORIES_REQUEST",
    DELETE_CATEGORIES_SUCCESS: " DELETE_CATEGORIES_SUCCESS",
    DELETE_CATEGORIES_FAILURE: " DELETE_CATEGORIES_FAILURE"
};
