import { CategoriesConstants } from "../Constants/Categories.constants";
import { CategorygetAll } from "../../Services/Categories.service";

export const CategoriesActions = {
    getAll
};

function getAll(menuId) {
    function request() {
        return { type: CategoriesConstants.GET_CATEGORIES_REQUEST };
    }
    function success(response) {
        return {
            type: CategoriesConstants.GET_CATEGORIES_SUCCESS,
            payload: response
        };
    }
    function failure(error) {
        return { type: CategoriesConstants.GET_CATEGORIES_FAİLURE };
    }

    return (dispatch) => {
        CategorygetAll(menuId).then((response) => dispatch(success(response)));
    };
}
