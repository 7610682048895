export const ProductsConstants = {
    GET_PRODUCTS_REQUEST: "GET_PRODUCTS_REQUEST",
    GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
    GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",

    STORE_OR_UPDATE_PRODUCTS_REQUEST: "STORE_OR_UPDATE_PRODUCTS_REQUEST",
    STORE_OR_UPDATE_PRODUCTS_SUCCESS: "STORE_OR_UPDATE_PRODUCTS_SUCCESS",
    STORE_OR_UPDATE_PRODUCTS_FAILURE: "STORE_OR_UPDATE_PRODUCTS_FAILURE",

    DELETE_PRODUCTS_REQUEST: " DELETE_PRODUCTS_REQUEST",
    DELETE_PRODUCTS_SUCCESS: " DELETE_PRODUCTS_SUCCESS",
    DELETE_PRODUCTS_FAILURE: " DELETE_PRODUCTS_FAILURE"
};
