import React from "react";
import HomeGrid from "./HomeGrid";
import WaiterScreen from "./Waiter/Waiter";
import Navbar from "./MyNavbar";
import MyFooter from "./MyFooter";
import ProductsDetailsCard from "./ProductsDetailsCard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import OrderDetail from "./Waiter/OrderDetail";
import OrderUpdate from "./Waiter/OrderUpdate";

function Root() {
    return (
        <Router>
            <>
                <Routes>
                    <Route path="/" element={<HomeGrid />} />,
                    <Route
                        path="/:restId/:tableId/:menuId"
                        element={<HomeGrid />}
                    />
                    ,
                    <Route
                        path="/:restId/:tableId/:menuId/Products/:id"
                        element={<ProductsDetailsCard />}
                    />
                    ,
                    <Route path="/waiters" element={<WaiterScreen />} />
                    <Route
                        exact
                        path="/waiters/order-detail/:id"
                        element={<OrderDetail />}
                    />
                    <Route
                        exact
                        path="/waiters/order-update/:id"
                        element={<OrderUpdate />}
                    />
                </Routes>
                <MyFooter />
            </>
        </Router>
    );
}

export default Root;
