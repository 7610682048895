import { OrderModalConstants } from "../Constants/OrderModal.constants";

export const OrderModalAction = {
    OpenOrderModal,
    CloseOrderModal
};

function OpenOrderModal() {
    return (dispatch) => {
        dispatch({
            type: OrderModalConstants.OPEN_ORDER_MODAL,
            payload: true
        });
    };
}

function CloseOrderModal() {
    return (dispatch) => {
        dispatch({
            type: OrderModalConstants.CLOSE_ORDER_MODAL,
            payload: false
        });
    };
}
