import React, { useEffect } from "react";
import { ProductsActions } from "../../Redux/Actions/Products.action";
import { CartActions } from "../../Redux/Actions/Cart.action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { useState } from "react";
import RecommendedSwipper from "../RecommendedSwipper";
import axios from "axios";
import MyNavbar from "../MyNavbar";
import { Stack } from "@mui/material";
import SearchDropdown from "../../Assest/SearchDropwdown";
import { Label } from "reactstrap";

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
};

function OrderUpdate() {
  const { id ,menuId } = useParams();
  const location = useLocation();
  let productDetails = {};
  const [orders, setOrders] = useState([]);
  const [ordersDetail, setOrdersDetail] = useState([]);
  const [allProducts, setAllProducts] = useState([])
  const [productId, setProductId] = useState("");


  const matchList=  [
    {
      key: "Anadolu Efes-Bahçeşehir Koleji (2022-09-03)",
      value: "jiPWBCeaxmgLqcEsiW78Cg=="
    },
    {
      key: "Fenerbahçe Beko-Anadolu Efes (2022-09-04)",
      value: "FVgExEs1PNn6nzi65UWxpw=="
    },
    {
      key: "Umana Reyer Venezia-Anadolu Efes (2022-09-20)",
      value:" uNOZ0Tr1JX5i26J4sTGQig=="
    },
    {
      key: "Panathinaikos-Anadolu Efes (2022-09-23)",
      value: "-rwB9l_XVNB-5lDwuabSDA=="
    },
   ]

function transformObject(data) {
    const { ProductId, ProductName } = data;
    const transformedData = [];
  
    for (let i = 0; i < ProductId.length; i++) {
      const obj = {
        key: ProductId[i],
        value: ProductName[i]
      };
      transformedData.push(obj);
    }
  
    return transformedData;
  }
  function formatData(data) {
    return data.map(item => ({
      value: item.Id,
      key: item.ProductName
    }));
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allProductResponse = await axios.post(
            `${config.apiUrl}/product/getAllData`,
            {
              MenuId: 1,
            }
          );
        const response = await axios.post(
          `${config.apiUrl}/orderRequest/getData`,
          {
            RestaurantId: 1,
            TableId: location.state.id,
          }
        );
        let newList = transformObject(response.data.Result);
        let newAllProducts = formatData(allProductResponse.data.Result)
        setOrdersDetail(response.data.Result)
        setAllProducts(newAllProducts)
        setOrders(newList);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [location.state.id]);
  console.log(orders);
  return (
    <>
      <MyNavbar text={"Bildirimler"} navbarType={"waiters"} />
      <div className="container Product-Detail-Container">
        {orders.map((product,i) => (
          <div className="row mt-5 border-top p-3">
            <div className="col-md-6 m-auto">
            <Stack sx={{ mb: 0, justifyContent: 'center' }}>
                <Label className="text-center mb-3 fs-4">{`${i+1}.Ürün`}</Label>
              <SearchDropdown
                value={product.value}
                options={allProducts}
                onChange={setProductId}
                variant="outlined"
                size="dense"
                disabled={allProducts.length < 1}
              />
            </Stack>
          </div></div>
        ))}
      </div>
    </>
  );
}

export default OrderUpdate;
