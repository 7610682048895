import React from "react";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import {
  BsFillHandIndexFill,
  BsCashCoin,
  BsFillCreditCard2BackFill,
} from "react-icons/bs";
import { useParams } from "react-router-dom";
import sendRequest from "../Helpers/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PopverMenu() {
  const { restId, tableId } = useParams();

  const handleAction = async (popType) => {
    const reqBody = {
      RestaurantId: Number(restId),
      PopOverTypeId: popType,
      TableId: Number(tableId),
      CreateUserId: 1,
    };
    try {
      if (popType) {
        if (popType === 1) {
          toast("Garsonu Çağırıldı!");
        } else if (popType === 2) {
          toast("Nakit Hesap İstendi!");
        } else if (popType === 3) {
          toast("Kart İle Hesap İstendi!");
        }
      }
      await sendRequest.post("/popOver/addData", reqBody);
    } catch (error) {
      console.log("fetch popover menu:", error);
    }
  };

  console.log(restId, tableId);
  return (
    <div className="Popover-Container">
      <button
        id="PopoverLegacy"
        type="Button"
        className="Popover-Button d-flex"
      >
        {/* <GiHamburgerMenu /> */}
        <p className="m-0">Garsonu Çağır</p>
      </button>
      <UncontrolledPopover
        placement="bottom"
        target="PopoverLegacy"
        trigger="legacy"
      >
        <PopoverBody className="d-flex justify-content-center rounded">
          <ul className="p-0">
            <button
              className="Popoever-list py-2 card-button"
              onClick={() => handleAction(1)}
            >
              <BsFillHandIndexFill className="Popoever-Icon" />
              <h6 className="d-flex m-0">Garsonu Çağır</h6>
            </button>
            <button
              className="Popoever-list mt-1 py-2 card-button"
              onClick={() => handleAction(2)}
            >
              <BsCashCoin className="Popoever-Icon" />
              <h6 className="d-flex m-0">Nakit İle Ödeme</h6>
            </button>
            <button
              className="Popoever-list mt-1 py-2 card-button"
              onClick={() => handleAction(3)}
            >
              <BsFillCreditCard2BackFill className="Popoever-Icon" />
              <h6 className="d-flex m-0">Kart İle Ödeme</h6>
            </button>
          </ul>
        </PopoverBody>
      </UncontrolledPopover>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
}

export default PopverMenu;
