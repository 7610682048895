import { ProductsConstants } from "../Constants/Products.constants";

const initialState = { products: [] };

export function Products(state = initialState, action) {
    switch (action.type) {
        // case ProductsConstants.GET_PRODUCTS_REQUEST:
        //     return {
        //         loading: true
        //     };
        case ProductsConstants.GET_PRODUCTS_SUCCESS:
            return {
                loading: false,
                products: action.payload.Result
            };
        // case ProductsConstants.GET_PRODUCTS_FAILURE:
        //     return {
        //         loading: true,
        //         error: action.error.message
        //     };
        default:
            return state;
    }
}
