import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ListGroup, ListGroupItem } from "reactstrap";
import axios from "axios";
import { unstable_HistoryRouter, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
};

function WaiterModal({ open, setOpen, item }) {
  const onCloseModal = () => setOpen(false);
  const [order, setOrder] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${config.apiUrl}/orderRequest/getData`,
          {
            RestaurantId: 1,
            TableId: item.Id,
          }
        );
        setOrder(response.data.Result);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [item]);

  const sendOrder = async () => {
    const reqBody = {
      RestaurantId: item.RestaurantId,
      OrderId: order.OrderId,
      ProductId: order.ProductId,
      TableId: item.Id,
      WaiterId: 1,
      OrderNote: order.OrderNotes,
      CreateUserId: 1,
    };
    try {
      await axios.post(`${config.apiUrl}/orderConfirmationAddData`, reqBody);
      toast("Sipariş Onaylandı!")
      onCloseModal();
     
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async () => {
    const reqBody = {
      Id: order.Id,
      UpdateUserId: 1,
      TableId: item.Id,
    };
    try {
      await axios.post(`${config.apiUrl}/orderRequest/deleteData`, reqBody);
      console.log("Başarılı");
      toast("Sipariş Silindi!")
      onCloseModal();
    } catch (error) {
      console.log("delete order request error:", error);
    }
  };

  console.log(item);
  if (!item) return null;
  return (
    <div>
      <Modal className="p-3" open={open} onClose={onCloseModal} center>
        <h2 className="text-center m-3 pe-3">
          {item.TableName + " " + "- İşlemler"}
        </h2>
        <div>
          <ListGroup flush>
            <ListGroupItem
              className="text-center p-3"
              color="success"
              action
              tag="button"
              onClick={sendOrder}
            >
              Siparişi Onayla
            </ListGroupItem>
            <ListGroupItem
              className="text-center p-3"
              color="info"
              action
              tag="button"
              onClick={() =>
                navigate(`/waiters/order-detail/${item.Id}`, {
                  state: { id: item.Id },
                })
              }
            >
              Siparişleri Gör
            </ListGroupItem>

            {/* <ListGroupItem
              className="text-center p-3"
              color="warning"
              action
              tag="button"
              onClick={() =>
                navigate(`/waiters/order-update/${item.Id}`, {
                  state: { id: item.Id },
                })
              }
              
            >
              Siparişi Düzenle
            </ListGroupItem> */}
            <ListGroupItem
              className="text-center p-3"
              color="danger"
              action
              tag="button"
              onClick={handleDelete}
            >
              Siparişi İptal Et
            </ListGroupItem>
          </ListGroup>
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
}

export default React.memo(WaiterModal);
