import React, { useState } from "react";
import { Navbar, NavbarBrand, Badge } from "reactstrap";
import { MdFoodBank, MdNotificationsActive } from "react-icons/md";
import OrderModal from "./OrderModal";
import Logo from "../Assest/Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { OrderModalAction } from "../Redux/Actions/OrderModal.action";
import { NavLink } from "react-router-dom";
import WaiterNotificationModal from "./Waiter/WaiterNotificationModal";

function MyNavbar({ text, navbarType,notificationData }) {
  const dispatch = useDispatch();
  let numberCart = useSelector((state) => state.CartReducer.numberCart);

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);


  const toggleModal = () => {
    dispatch(OrderModalAction.OpenOrderModal());
  };
console.log(notificationData)
  return (
    <div>
      <Navbar className="my-2 navbar shadow fixed-top bg-white">
        <NavbarBrand>
            <img className="logo" src={Logo} alt="" />
        </NavbarBrand>
        <div className="justify-content-end float-left d-flex navRight">
          <div
            className="d-flex justify-content-center cursor "
            onClick={() =>
              navbarType === "orders" ? toggleModal() : onOpenModal()
            }
          >
           {navbarType === "orders" ?  <MdFoodBank className="nav-order-icon" /> : <MdNotificationsActive className="nav-order-icon"/>}
            <p className="mt-3 text-dark fw-semibold">{text}</p>

            <Badge bg="danger" className="nav-badge">
              {navbarType === "orders" ? numberCart : notificationData?.length}
            </Badge>
          </div>
          <OrderModal modalType={navbarType} />
          {navbarType === "waiters" && (
            <WaiterNotificationModal setOpen={setOpen} open={open} notificationData={notificationData} />
          )}
        </div>
      </Navbar>
    </div>
  );
}

export default MyNavbar;
