import { OrderModalConstants } from "../Constants/OrderModal.constants";

const initialState = { onOpenModal: false };

export function OrderModal(state = initialState, action) {
    switch (action.type) {
        case OrderModalConstants.OPEN_ORDER_MODAL:
            return {
                onOpenModal: action.payload
            };
        case OrderModalConstants.CLOSE_ORDER_MODAL:
            return {
                onOpenModal: action.payload
            };

        default:
            return state;
    }
}
