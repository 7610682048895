import { CategoriesFilterConstants } from "../Constants/CategoriesFilter.constants";

export const CategoriesFilterActions = {
    setCatFilter
};

function setCatFilter(CategoriesId) {
    return (dispatch) => {
        dispatch({
            type: CategoriesFilterConstants.GET_CATEGORIES_ID,
            payload: CategoriesId
        });
    };
}
