import { combineReducers } from "redux";
import { Categories } from "./Categories.reducer";
import { Products } from "./Products.reducer";
import { CategoriesFilter } from "./CategoriesFilter.reducer";
import { OrderModal } from "./OrderModal.reducer";
import { CartReducer } from "./Cart.reducer";

const rootReducer = combineReducers({
    Categories,
    Products,
    CategoriesFilter,
    OrderModal,
    CartReducer
});

export default rootReducer;
