import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";
import { ProductsActions } from "../Redux/Actions/Products.action";
import { CartActions } from "../Redux/Actions/Cart.action";
import { NavLink, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ProductCard() {
  const dispatch = useDispatch();
  let products = useSelector((state) => state.Products.products);
  let filter = useSelector((state) => state.CategoriesFilter.filter);
  const { menuId, restId, tableId } = useParams();
  const [filteredProduct, setfilteredProduct] = useState(products);

  const addToCart = (product) => {
    dispatch(CartActions.addToCart(product));
    toast("Sipariş Sepete Eklendi!");
  };
  const getProducts = (menuId) => {
    dispatch(ProductsActions.getAll(menuId));
  };

  useEffect(() => {
    filter === "all"
      ? setfilteredProduct(products)
      : setfilteredProduct(products.filter((x) => x.CategoryId === filter));
  }, [filter, products]);

  useEffect(() => {
    if (menuId) getProducts(menuId);
  }, []);
  if (!products) return null;
  return (
    <div className="Product-Container">
      <div className="row">
        {filteredProduct.map((product, i) => (
          <div
            className="col-6 col-md-4 col-xl-3 my-2 product-card-col"
            key={i}
          >
            <Card inverse>
              <NavLink
                to={`Products/ ${product.Id}`}
                className="text-decoration-none"
              >
                <img
                  className="card-img-top img-fluid m-0 product-card-img shadow-sm"
                  alt="Sample"
                  src={product.ProductImageUrl}
                />
              </NavLink>
              <CardBody className="card-body Products-Card-Body border border-1 shadow-sm">
                <CardTitle
                  className="card-title card-text text-center text-dark my-1 product-cart-name"
                  tag="h5"
                >
                  {product.ProductName}
                </CardTitle>
                <CardText
                  className="cartext text-center text-dark my-1"
                  tag="p"
                  color="dark"
                >
                  Some quick example text to build on the card
                </CardText>
                <CardTitle
                  className=" d-flex justify-content-center card-text text-dark"
                  tag="h4"
                >
                  {product.ProductPrice}&#x20BA;
                </CardTitle>
                <div className="Products-Card-Button-Container">
                  <button
                    className="btn btn-outline-primary card-button "
                    onClick={() => addToCart(product)}
                  >
                    <small>Siparişe Ekle</small>
                  </button>
                </div>
              </CardBody>
            </Card>
          </div>
        ))}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={10}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
    </div>
  );
}

export default ProductCard;
