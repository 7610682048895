import { ProductsConstants } from "../Constants/Products.constants";
import { ProductgetAll } from "../../Services/Products.service";

export const ProductsActions = {
    getAll
};

function getAll(menuId) {
    function request() {
        return { type: ProductsConstants.GET_PRODUCTS_REQUEST };
    }
    function success(response) {
        return {
            type: ProductsConstants.GET_PRODUCTS_SUCCESS,
            payload: response
        };
    }
    function failure(error) {
        return { type: ProductsConstants.GET_PRODUCTS_FAİLURE };
    }

    return (dispatch) => {
        ProductgetAll(menuId).then((response) => dispatch(success(response)));
    };
}
