import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "reactstrap";
import { CartActions } from "../Redux/Actions/Cart.action";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
};

function RecommendedSwipper({ categoryId, currentProductId }) {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const { id, menuId, restId, tableId } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${config.apiUrl}/product/getAllData`,
          {
            MenuId: Number(menuId),
          }
        );
        let filterRes = response.data.Result.filter(
          (item) =>
            item.CategoryId == categoryId && item.Id !== currentProductId
        );
        setProducts(filterRes);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [currentProductId]);
  console.log(typeof currentProductId);
  const addToCart = (product) => {
    dispatch(CartActions.addToCart(product));
  };
  return (
    <Swiper
      slidesPerView={2}
      spaceBetween={30}
      modules={[Pagination]}
      className="mySwiper"
      breakpoints={{
        180: {
          slidesPerView: 1.5,
          spaceBetween: 10,
        },
        320: {
          slidesPerView: 1.5,
          spaceBetween: 10,
        },

        480: {
          slidesPerView: 2.5,
          spaceBetween: 10,
        },

        720: {
          slidesPerView: 3.5,
          spaceBetween: 10,
        },
        1280: {
          slidesPerView: 4.5,
          spaceBetween: 10,
        },
        1440: {
          slidesPerView: 5.5,
          spaceBetween: 10,
        },
      }}
    >
      {products.map((product, i) => {
        return (
          <SwiperSlide className="mt-3" key={i}>
            <Card inverse>
              <img
                className="card-img-top img-fluid m-0 product-card-img Product-Details-Swipper-img shadow-sm "
                alt="Sample"
                src={product.ProductImageUrl}
              />

              <CardBody className="card-body border border-1 Product-Details-Swipper-cart shadow-sm ">
                <CardTitle
                  className="card-title card-text text-center text-dark my-2 product-cart-name"
                  tag="h5"
                >
                  {product.ProductName}
                </CardTitle>
                <CardTitle
                  className=" d-flex justify-content-center card-text text-dark my-2"
                  tag="h4"
                >
                  {product.ProductPrice}&#x20BA;
                </CardTitle>
                <div className="Products-Card-Button-Container">
                  {/* 
                                        <small> İncele</small>
                                     */}
                  <button
                    className="btn btn-outline-primary card-button "
                    onClick={() => addToCart(product)}
                  >
                    <small>Siparişe Ekle</small>
                  </button>
                </div>
              </CardBody>
            </Card>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default RecommendedSwipper;
