import axios from "axios";

const config = {
    apiUrl: process.env.REACT_APP_API_URL
};

export const ProductgetAll = async (menuId) => {
    return await axios
        .post(`${config.apiUrl}/product/getAllData`, { MenuId: Number(menuId) })
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            return Promise.reject(error);
        });
};
